// Small devices (landscape phones, 576px and up)
@media (min-width: $sm) {
    .flex-sm-c {
        display: flex;
        flex-direction: column;
    }

    .flex-sm-r {
        display: flex;
        flex-direction: row;
    }

    .hero-grid {
        width: $hero-sm-grid;
        .box {
            height: $grid-sm-box;
            width: $grid-sm-box;
            .widget {
                height: $grid-sm-widget;
                width: $grid-sm-widget;
                i {
                    font-size: rem(32px);
                }
                span {
                    font-size: rem(14px);
                }
            }
        }
    }

    .nav {
        padding: rem(8px 15px);
        width: rem(540px);
    }

    .scroll {
        .nav {
            padding: rem(8px 15px);
        }
    }

    .nav-offset {
        margin-top: $nav-height;
    }

    // Headings

    h1 {
        font-size: rem(48px);
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $md) {
    .flex-md-c {
        display: flex !important;
        flex-direction: column !important;
    }

    .flex-md-r {
        display: flex;
        flex-direction: row;
    }

    .nav {
        padding: rem(16px 15px);
        width: rem(720px);
    }

    .nav-offset {
        margin-top: $nav-height + rem(32px);
    }

    .skill {
        .icon-wrap {
            margin-right: rem(32px);
        }
    }

    .width {
        &-48 {
            width: 48%;
        }
        &-4 {
            width: 4%;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: $lg) {
    .flex-lg-c {
        display: flex;
        flex-direction: column;
    }

    .flex-lg-r {
        display: flex;
        flex-direction: row;
    }

    nav {
        .pog:hover {
            background-color: transparentize($white, 0.8);
            cursor: pointer;
            transition: all 0.25s;
        }
    }

    .nav {
        width: rem(960px);
    }

    .scroll {
        nav {
            .pog:hover {
                background-color: $white;
            }
        }
    }

    .sch-icon {
        align-items: center;
        border-bottom-left-radius: rem(16px);
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        justify-content: center;
        padding: rem(0 24px);
        i {
            font-size: rem(40px);
        }
    }

    .school {
        background-color: transparentize($white, 0.9);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: rem(16px);
        border-top-right-radius: rem(16px);
    }

    .feat-mr {
        margin-bottom: 0;
    }

    .btn {
        transition: all 0.25s;
        &-p {
            &:hover {
                background-color: darken($green, 15%);
                cursor: pointer;
                text-decoration: underline;
                transition: all 0.25s;
            }
        }
        &-s {
            &:hover {
                cursor: pointer;
                text-decoration: underline;
                transition: all 0.25s;
            }
        }
    }

    .hero-grid {
        .box {
            .widget:hover {
                background-color: $white;
                color: $black;
                cursor: pointer;
                transition: all 0.25s;
            }
        }
    }

    .profile-img-wrap {
        &:hover {
            background-color: $white;
            .profile-img {
                background: url('../img/Rick-Prof.jpg') center center;
                background-size: 100%;
                transition: all 0.25s;
            }
        }
    }

    .gun:hover {
        border: solid rem(4px) $green;
        cursor: pointer;
        height: rem(84px);
        transition: all 0.25s;
        width: rem(84px);
    }

    .hover {
        cursor: pointer;
    }

    .text-md {
        &-l {
            text-align: left;
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $xl) {
    .flex-xl-c {
        display: flex;
        flex-direction: column;
    }

    .flex-xl-r {
        display: flex;
        flex-direction: row;
    }

    .nav {
        width: rem(1140px);
    }
}
