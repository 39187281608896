// Breakpoints

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #0a0c0a !default;

$blue: #33a9d1 !default;
$blue-d: #122b32 !default;

$green: #9cd133 !default;
$green-d: #273312 !default;

$red: #d14c33 !default;
$red-d: #321913 !default;

// Components

$nav-height: rem(80px);

$hero-grid: rem(260px);
$hero-sm-grid: rem(600px);

$grid-box: $hero-grid / 3;
$grid-sm-box: rem(200px);

$grid-widget: rem(44px);
$grid-sm-widget: rem(128px);
