// Components

nav {
    background-color: transparentize($white, 0.9);
    position: fixed;
    transition: all 0.25s;
    width: 100%;
    z-index: 1001;
    .email {
        color: $green;
    }
    i {
        color: $white;
    }
    .addy {
        color: $white;
    }
    .pog {
        align-items: center;
        border-radius: rem(20px);
        height: rem(40px);
        justify-content: center;
        width: rem(40px);
    }
    a {
        text-decoration: none;
    }
}

.nav {
    padding: rem(8px 15px);
    transition: all 0.25s;
    width: 100%;
}

.scroll {
    nav {
        background-color: transparentize($green, 0.1);
        transition: all 0.25s;
        .email,
        .addy,
        i {
            color: $black;
        }
    }
    .nav {
        transition: all 0.25s;
    }
    .profile-img-wrap {
        background-color: transparentize($black, 0.9);
    }
}

.stop {
    max-height: 100vh;
    overflow-y: hidden;
}

.profile-img-wrap {
    align-items: center;
    background-color: transparentize($white, 0.9);
    border-radius: rem(20px);
    height: rem(40px);
    justify-content: center;
    width: rem(40px);
    .profile-img {
        background: url('../img/Self.jpeg') center center;
        background-repeat: no-repeat;
        background-size: 100%;
        border-radius: rem(16px);
        height: rem(32px);
        overflow: hidden;
        transition: all 0.25s;
        width: rem(32px);
    }
}

.spin-me {
    animation: spin 2s infinite linear;
    border: solid 1px $green;
    border-radius: 100%;
    border-right: 0;
    border-top: 0;
    display: flex;
    height: rem(40px);
    left: 0;
    top: 0;
    transition: transform 0.4s ease;
    position: absolute;
    width: rem(40px);
    z-index: -1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.nav-offset {
    margin-top: $nav-height;
}

.intro {
    background-color: $black;
    background-image: url('../img/Pattern.png');
    background-repeat: repeat;
    background-size: 33.333%;
}

.intro-fade {
    position: relative;
    &::before {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0a0c0a+0,0a0c0a+28&0+0,1+28 */
        background: -moz-linear-gradient(
            top,
            rgba(10, 12, 10, 0) 0%,
            rgba(10, 12, 10, 1) 28%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            rgba(10, 12, 10, 0) 0%,
            rgba(10, 12, 10, 1) 28%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            rgba(10, 12, 10, 0) 0%,
            rgba(10, 12, 10, 1) 28%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000a0c0a', endColorstr='#0a0c0a',GradientType=0 ); /* IE6-9 */
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.hero-grid {
    flex-wrap: wrap;
    width: $hero-grid;
    .box {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: $grid-box;
        justify-content: center;
        width: $grid-box;
        .widget {
            align-items: center;
            background-color: transparentize($white, 0.9);
            border-radius: 100%;
            color: $white;
            display: flex;
            flex-direction: column;
            height: 90%;
            justify-content: center;
            transition: all 0.25s;
            width: 90%;
            i {
                font-size: rem(18px);
            }
            span {
                font-size: rem(10px);
            }
        }
        .rick {
            background-image: url('../img/Rick-min.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            height: 100%;
            width: 100%;
        }
    }
}

.overlay {
    background-color: transparentize($black, 0.1);
}

.feature-tile {
    align-items: center;
    border-style: solid;
    border-radius: rem(16px);
    border-width: rem(2px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(48px 32px);
    .badge {
        align-items: center;
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        height: rem(100px);
        justify-content: center;
        width: rem(100px);
        i {
            font-size: rem(40px);
        }
    }
}

.feat-mr {
    border-top-left-radius: rem(16px);
    border-top-right-radius: rem(16px);
    border-bottom-width: 0 !important;
    height: rem(24px);
    margin-top: rem(8px);
    margin-bottom: rem(40px);
    opacity: 30%;
    position: relative;
    width: 100%;
    &::after {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0a0c0a+0,0a0c0a+60,0a0c0a+100&0+0,1+60,1+100 */
        background: -moz-linear-gradient(
            top,
            rgba(10, 12, 10, 0) 0%,
            rgba(10, 12, 10, 1) 60%,
            rgba(10, 12, 10, 1) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            rgba(10, 12, 10, 0) 0%,
            rgba(10, 12, 10, 1) 60%,
            rgba(10, 12, 10, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            rgba(10, 12, 10, 0) 0%,
            rgba(10, 12, 10, 1) 60%,
            rgba(10, 12, 10, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000a0c0a', endColorstr='#0a0c0a',GradientType=0 ); /* IE6-9 */
        content: '';
        height: rem(32px);
        left: -3%;
        position: absolute;
        width: 106%;
    }
    &-b {
        border: solid 2px $blue;
        box-shadow: $blue 0px 0px 8px 0px;
    }
    &-g {
        border: solid 2px $green;
        box-shadow: $green 0px 0px 8px 0px;
    }
    &-r {
        border: solid 2px $red;
        box-shadow: $red 0px 0px 8px 0px;
    }
}

.case {
    width: 100%;
    .frame {
        background-color: $white;
        border: solid 2px $white;
        border-radius: rem(16px);
        height: rem(500px);
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &-1 {
            background-image: url('../img/thumbs/case-1-thumb-min.png');
        }
        &-2 {
            background-image: url('../img/thumbs/case-2-thumb-min.png');
        }
        &-3 {
            background-image: url('../img/thumbs/case-3-thumb-min.png');
        }
        &-4 {
            background-image: url('../img/thumbs/case-4-thumb-min.png');
        }
    }
}

.skill {
    align-items: center;
    margin-top: rem(32px);
    .icon-wrap {
        align-items: center;
        background-color: transparentize($white, 0.9);
        border-radius: 100%;
        display: flex;
        height: rem(100px);
        justify-content: center;
        margin-right: rem(16px);
        position: relative;
        overflow: hidden;
        width: rem(100px);
        &::after {
            background-color: $white;
            border-radius: 100%;
            content: '';
            height: 88%;
            position: absolute;
            width: 88%;
        }
        i {
            font-size: rem(32px);
        }
    }
    .stat {
        border-radius: rem(4px);
        height: rem(8px);
        margin-top: rem(12px);
        width: 100%;
        div {
            border-radius: rem(4px);
            height: rem(8px);
            width: 100%;
        }
    }
}

.job {
    align-items: center;
    background-color: transparentize($white, 0.95);
    border-radius: rem(16px);
    margin-top: rem(32px);
    padding: rem(32px 40px);
}

.sch-icon {
    align-items: center;
    border-top-left-radius: rem(16px);
    border-top-right-radius: rem(16px);
    justify-content: center;
    padding: rem(24px 0);
    i {
        font-size: rem(40px);
    }
}

ul {
    list-style-type: none;
    margin: rem(8px 0 16px);
    padding-left: rem(4px);
    li {
        margin-bottom: rem(8px);
        padding-left: rem(24px);
        position: relative;
        &::before {
            position: absolute;
            color: $gray-600;
            content: '\f30b';
            font: 14px/1 FontAwesome;
            left: 0;
            margin-right: rem(8px);
            top: rem(4px);
        }
    }
}

.school {
    background-color: transparentize($white, 0.9);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.gun-wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: rem(84px);
    justify-content: center;
    position: absolute;
    top: rem(-84px/2);
    left: auto;
    width: 100%;
    .gun {
        align-items: center;
        align-self: center;
        background-color: $white;
        background-image: url('../img/Gun.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        border: solid rem(4px) $green;
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        height: rem(60px);
        justify-content: center;
        transition: all 0.25s;
        width: rem(60px);
    }
}

footer {
    padding: rem(48px 0);
    position: relative;
}

.firewall,
.not-found {
    min-height: 100vh;
    img {
        width: 100%;
    }
}

.fingers {
    margin: 0 auto;
    text-align: center;
    width: 100%;
}

.divider {
    border-bottom: solid 1px $gray-300;
}

.form-control {
    background-clip: padding-box;
    background-color: $white;
    border: 1px solid $gray-300;
    border-radius: rem(24px);
    color: $black;
    display: block;
    font-weight: 500;
    line-height: rem(20px);
    min-height: rem(40px);
    padding: rem(8px 12px);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.modal-background {
    align-items: center;
    background-color: transparentize($black, 0.1);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1003;
}

.modal-content {
    background-color: $white;
    border-radius: rem(6px);
    display: flex;
    flex-direction: column;
    padding: rem(16px 16px 32px);
}

.modal-header {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    margin-bottom: rem(8px);
    .white {
        align-items: center;
        background-color: $green;
        border-radius: rem(48px);
        display: flex;
        flex-direction: column;
        font-size: rem(18px);
        height: rem(48px);
        justify-content: center;
        margin-bottom: rem(12px);
        margin-top: rem(32px);
        width: rem(48px);
    }
    .fa-xmark {
        cursor: pointer;
    }
}

.modal-title {
    flex: 1;
    font-size: rem(24px);
    font-weight: 700;
    margin: 0;
}

.modal-body {
    padding: rem(0 16px 16px);
    text-align: center;
}

// Buttons

.btn {
    align-items: center;
    border-radius: rem(24px);
    display: flex;
    font-size: rem(16px);
    justify-content: center;
    flex-direction: row;
    font-weight: 700;
    padding: rem(10px 20px);
    text-decoration: none;
    &-p {
        background-color: $green;
        color: $black;
    }
    &-s {
        background-color: $black;
        border: solid 2px $green;
        color: $green;
    }
}

// Borders

.border-blue {
    border-color: $blue;
}

.border-green {
    border-color: $green;
}

.border-red {
    border-color: $red;
}

.border-none {
    border: none;
}

// Colors

.bck {
    &-white {
        background-color: $white;
    }
    &-black {
        background-color: $black;
    }
    &-blue {
        background-color: $blue;
    }
    &-blue-d {
        background-color: $blue-d;
    }
    &-green {
        background-color: $green;
    }
    &-green-d {
        background-color: $green-d;
    }
    &-red {
        background-color: $red;
    }
    &-red-d {
        background-color: $red-d;
    }
}

// Resets

.b-radius-reset-r {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.b-radius-reset-l {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-r {
    background: none;
    background-color: transparent;
    border: none;
    outline: none;
}
