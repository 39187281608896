// Sizes

.font-sm {
    font-size: rem(14px) !important;
}

.font-md {
    font-size: rem(18px) !important;
}

.font-lg {
    font-size: rem(21px) !important;
}

.font-xl {
    font-size: rem(32px) !important;
}

// Colors

.blue {
    color: $blue !important;
}

.green {
    color: $green !important;
}

.red {
    color: $red !important;
}

.white {
    color: $white !important;
}

.black {
    color: $black !important;
}

.g-600 {
    color: $gray-600 !important;
}

.g-700 {
    color: $gray-700 !important;
}

// Weights

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

// Alignments

.text {
    &-c {
        text-align: center;
    }
}

// Headings

h1 {
    font-size: rem(32px);
}
