.flex {
    &-c {
        display: flex;
        flex-direction: column;
    }
    &-r {
        display: flex;
        flex-direction: row;
    }
    &-none {
        display: none;
    }
    &-1 {
        flex: 1;
    }
    &-2 {
        flex: 2;
    }
}

.mt-4-5 {
    margin-top: rem(32px);
}

.width {
    &-48 {
        width: 100%;
    }
}

.pad-offset {
    padding-left: rem(15px);
    padding-right: rem(15px);
}

.p-relative {
    position: relative;
}

.z-res {
    z-index: 1;
}
