html {
    display: flex;
    flex-direction: column;
}

body {
    color: $black;
    margin: 0;
    font-family: 'Source Code Pro', monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: rem(16px);
}

body,
#root {
    background-color: $black;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

p {
    line-height: rem(24px);
    margin: 0;
    padding: 0;
}
